<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem1">
          <div class="listbox">
            <div class="name">姓名：{{name}}</div>
            <div class="unames1">电话：{{mobile}}</div>
            <div class="unames1 red">制卡：{{card_date}}</div>
          </div>          
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">通行二维码</div>
            <img :src="picture_ewm_url" class="iconewm" />
            <div class="ll">
              <div class="button1 primary mt" @click="printGo">打印通行证</div>          
            </div>
            <div class="ll">
              <div class="bd" @click="bindPrint">绑定打印机</div>          
            </div>
            
            
          </div>
          
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {   

    let { wxdj_uuid } = this.$route.query;
    this.wxdj_uuid = wxdj_uuid;
    let { data } = await this.axios.post(
      '/ctid/TlZhService/pz_selectpicewm_gg',
      qs.stringify({
        wxdj_uuid: this.wxdj_uuid
      })
    );
    if (data.flag === '0') {
      this.name = data.data.name;
      //this.idnum = data.data.idnum;
      this.mobile = data.data.mobile;
      this.picture_ewm_url = data.data.picture_ewm;     
      var stmp = data.data.log_date;  
      //alert(stmp); 
      if(!isEmpty(stmp)) {
        this.card_date = stmp.substring(0,10);
      }
      
      //this.picture_url = data.data.picture;
          
    }

    
    
  },
  data() {
    return {
      data: {
        kgflag: false
      },
      name:'',
      idnum:'',
      mobile:'',
      card_date:'',
      picture_url:'',
      picture_ewm_url:'',
      wxdj_uuid:'',
      flag: false
    };
  },
  methods: {
    async printGo() {
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_dayin_gg',
        qs.stringify({
          wxdj_uuid: this.wxdj_uuid
        })
      );
      if (data.flag === '0') {
        //Toast('提交成功!');
        Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>"+data.message });
        this.$router.replace('/user/my');
      }else {
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });
      }
      
    },
    bindPrint() {
      this.$router.push('/publicregister/bind_print_zc?wxdj_uuid=' + this.wxdj_uuid);
    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 210px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 30px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
        &.red {
          color: rgb(36, 23, 221);
        }
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 320px;   
        height: 203px;  
        float: right;
        margin-right: 34px;
      }    
    }   
  }
  .listitem2 {
    overflow: hidden;
    height: 760px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }  
      .iconewm {
        margin-top: 20px;
        margin-left: 150px;
        width: 400px;   
        height: 400px;  
      }  
      
      .ll {
        width: 90%;
        margin: 0px auto;
      }
      
    }

    
  }
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(0, 98, 255, 1) 0%, rgba(99, 52, 255, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}

.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 80%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
.bd {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #2c4dff;
  border-radius: 8px;
  margin-top: 15px;
  cursor: pointer;

}
</style>